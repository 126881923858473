.zustimmungHygieneRichtlinie{
    font-size:10px
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.banner {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 100px;
}

.banner  ul a {
	color: #ffffff !important;
	text-decoration: none;
	border: 1px solid #ffffff;
	font-size: 14px;
	display: block;
	line-height: 14px;
	padding: 4px 6px;
	width: 220px !important;
    text-align: center !important;
}

.banner  ul a:hover {opacity: 0.7;}

.logo, .logo_en {
	display: block;
	width: 220px;
	padding: 15px 15px;
	position: absolute;
	left: 0px;
	top: 0px;
	border-bottom: 0px dashed #335784 !important;
}

.logo img, #logo_en img {
	display: block;
	width: 100%;
	height: auto;
}
.header{
    position: fixed;
    height: 81px;
    width: 100%;
    z-index: 1000;
    background: rgba(47, 72, 102, 1);
    background: -moz-linear-gradient(-45deg, rgba(47, 72, 102, 1) 0%, rgba(109, 127, 148, 1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(47, 72, 102, 1) 0%, rgba(109, 127, 148, 1) 100%);
    background: linear-gradient(135deg, rgba(47, 72, 102, 1) 0%, rgba(109, 127, 148, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2f4866', endColorstr='#6d7f94', GradientType=1);
    margin: 0px;
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    display: block;
    margin: 0px auto;
    max-width: 940px;
}

.body
{
    padding-top: 100px;
    margin-bottom: 100px;
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
    display: block;
    margin: 0px auto;
    max-width: 940px;
    font-size:12px;
}
@media (min-width: 768px) {
    .container {
      width: 750px;
    }
}
 
@media (min-width: 992px) {
    .container {
      width: 970px;
    }
}
  
@media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
}
.submitButton{
    padding:1rem 1rem;
    background-color:#ADD8E6;
    grid-row:3;
    grid-column-start:1;
    grid-column-end: 3;
    color:white;
    font-size: 1.5rem;
}
.submitButtonCheckboxChecked
{ 
    padding:1rem 1rem;
    background-color:#003576;
    grid-row:3;
    grid-column-start:1;
    grid-column-end: 3;
    color:white;
    font-size: 1.5rem;
    cursor: pointer;
}
.zustimmungContainer
{
    padding:3rem 0rem;
    font-size:2rem;
    display:grid;
    gap:0.5rem;
}
.zustimmungLabel{
    grid-row-start:1;
    grid-row-end:3;
    grid-column:2;
    font-size:1.5rem;
    font-weight: 600;
}
.zustimmungCheckbox
{
    grid-row:1;
    grid-column:1;
    cursor: pointer;
}