.hygieneRichtlinieText{
   display:flex;
   flex-direction: column;
}
.header{
    font-weight: bolder;
    padding: 10px 0px;
}
.span
{
    padding: 10px 0px;
}
.footer{
    font-style: italic;
    font-size:1.2em;
    text-align: center;
}
.spanDsvgo{
    padding: 5px 0px;
    font-size:10px;
}